const FORM_ERROR = 'ANALYTICS_FORM_ERROR';
const NAVIGATION_CLICK = 'ANALYTICS_NAVIGATION_CLICK';
const APP_BANNER_OPEN = 'APP_BANNER_OPEN';
const APP_BANNER_CLOSE = 'APP_BANNER_CLOSE';
const ANALYTICS_SELECT_STORE = 'ANALYTICS_SELECT_STORE';
const ANALYTICS_VIDEO_LOADED = 'ANALYTICS_VIDEO_LOADED';
const ANALYTICS_OPEN_MODAL = 'ANALYTICS_OPEN_MODAL';
const UPDATE_ANALYTICS_CLICK_EVENT = 'UPDATE_ANALYTICS_CLICK_EVENT';
const REMOVE_ANALYTICS_CLICK_EVENT = 'REMOVE_ANALYTICS_CLICK_EVENT';
const ANALYTICS_STORE_SEARCH = 'ANALYTICS_STORE_SEARCH';
const BOPIS_CHANGE_ZIP = 'BOPIS_CHANGE_ZIP';
const COMPARE_PRODUCTS_CLICK = 'COMPARE_PRODUCTS_CLICK';
const PDP_TOOL_CLICK_ANALYTICS = 'PDP_TOOL_CLICK_ANALYTICS';
const PDP_KA_CLICK_ANALYTICS = 'PDP_KA_CLICK_ANALYTICS';
const PDP_OPEN_MODAL_ANALYTICS = 'PDP_OPEN_MODAL_ANALYTICS';
const PDP_PRODUCT_OOS_ANALYTICS = 'PDP_PRODUCT_OOS_ANALYTICS';
const LOG_CUSTOM_ERRORS = 'LOG_CUSTOM_ERRORS';
const PRODUCT_RECOMMENDATIONS_ANALYTICS = 'PRODUCT_RECOMMENDATIONS_ANALYTICS';
const BREADCRUMB_ANALYTICS = 'BREADCRUMB_ANALYTICS';
const PROMOTED_FILTERS_APPLIED = 'PROMOTED_FILTERS_APPLIED';
const FILTERS_BY_PILLS_ANALYTICS = 'FILTERS_BY_PILLS_ANALYTICS';
const FITFINDER_MODAL_AND_PERSONA = 'FITFINDER_MODAL_AND_PERSONA';
const GALLERY_VIDEO_PLAYER_ANALYTICS = 'GALLERY_VIDEO_PLAYER_ANALYTICS';
const TRIGGER_PAGE_READY = 'TRIGGER_PAGE_READY';
const TRIGGER_ACTIVE_CART_REMINDER = 'TRIGGER_ACTIVE_CART_REMINDER';
const TRIGGER_BOPIS_MODAL = 'TRIGGER_BOPIS_MODAL';
const TRIGGER_BOPIS_PICKUP_STORE_CHANGE = 'TRIGGER_BOPIS_PICKUP_STORE_CHANGE';
const TRIGGER_BOPIS_DEFAULT_STORE_CHANGE = 'TRIGGER_BOPIS_DEFAULT_STORE_CHANGE';
const TRIGGER_SKINCARE_MODAL = 'TRIGGER_SKINCARE_MODAL';
const TRIGGER_VTO_MODAL = 'TRIGGER_VTO_MODAL';
const TRIGGER_REVIEVE_ADDTOCART_ANALYTICS = 'TRIGGER_REVIEVE_ADDTOCART_ANALYTICS';
const PLCC_APPLY_ANALYTICS = 'PLCC_APPLY_ANALYTICS';
const PLCC_VIEWS_ANALYTICS = 'PLCC_VIEWS_ANALYTICS';
const ANALYTICS_SUCCESSFUL_SUBSCRIPTION = 'ANALYTICS_SUCCESSFUL_SUBSCRIPTION';
const PDP_SHOP_THE_LOOK_READY = 'PDP_SHOP_THE_LOOK_READY';
const PDP_SHOP_THIS_LOOK_CLICK_EVENT = 'PDP_SHOP_THIS_LOOK_CLICK_EVENT';
const PDP_SHOP_THE_LOOK_MINI_PDP_CLICK_EVENT = 'PDP_SHOP_THE_LOOK_MINI_PDP_CLICK_EVENT';
const ADI_SITE_PROBE_SUCCESS_ANALYTICS = 'ADI_SITE_PROBE_SUCCESS_ANALYTICS';
const REPORT_WEB_VITALS = 'REPORT_WEB_VITALS';
const STYLED_FOR_YOU_ITEM_CLICK = 'STYLED_FOR_YOU_ITEM_CLICK';
const STYLED_FOR_YOU_LOAD_SUCCESS = 'STYLED_FOR_YOU_LOAD_SUCCESS';
const STYLED_FOR_YOU_MODULE_CLICK = 'STYLED_FOR_YOU_MODULE_CLICK';
const TRIGGER_HAIRCARE_MODAL = 'TRIGGER_HAIRCARE_MODAL';
const PDP_ALL_SKU_SELECTED = 'PDP_ALL_SKU_SELECTED';
const BNPL_ANALYTICS_EVENT = 'BNPL_ANALYTICS_EVENT';
const OOS_SPONSORED_PRODUCTFORM_ERROR = 'OOS_SPONSORED_PRODUCTFORM_ERROR';
const PLCC_ANALYTICS_EVENT = 'PLCC_ANALYTICS_EVENT';
export default {
    APP_BANNER_OPEN,
    APP_BANNER_CLOSE,
    FORM_ERROR,
    NAVIGATION_CLICK,
    ANALYTICS_SELECT_STORE,
    ANALYTICS_VIDEO_LOADED,
    ANALYTICS_OPEN_MODAL,
    UPDATE_ANALYTICS_CLICK_EVENT,
    REMOVE_ANALYTICS_CLICK_EVENT,
    ANALYTICS_STORE_SEARCH,
    BOPIS_CHANGE_ZIP,
    COMPARE_PRODUCTS_CLICK,
    PDP_TOOL_CLICK_ANALYTICS,
    PDP_KA_CLICK_ANALYTICS,
    PDP_OPEN_MODAL_ANALYTICS,
    PDP_PRODUCT_OOS_ANALYTICS,
    LOG_CUSTOM_ERRORS,
    PRODUCT_RECOMMENDATIONS_ANALYTICS,
    BREADCRUMB_ANALYTICS,
    FITFINDER_MODAL_AND_PERSONA,
    PROMOTED_FILTERS_APPLIED,
    FILTERS_BY_PILLS_ANALYTICS,
    GALLERY_VIDEO_PLAYER_ANALYTICS,
    TRIGGER_PAGE_READY,
    TRIGGER_ACTIVE_CART_REMINDER,
    TRIGGER_BOPIS_MODAL,
    TRIGGER_BOPIS_PICKUP_STORE_CHANGE,
    TRIGGER_BOPIS_DEFAULT_STORE_CHANGE,
    TRIGGER_SKINCARE_MODAL,
    TRIGGER_VTO_MODAL,
    TRIGGER_REVIEVE_ADDTOCART_ANALYTICS,
    PLCC_APPLY_ANALYTICS,
    PLCC_VIEWS_ANALYTICS,
    ANALYTICS_SUCCESSFUL_SUBSCRIPTION,
    PDP_SHOP_THE_LOOK_READY,
    PDP_SHOP_THIS_LOOK_CLICK_EVENT,
    PDP_SHOP_THE_LOOK_MINI_PDP_CLICK_EVENT,
    ADI_SITE_PROBE_SUCCESS_ANALYTICS,
    REPORT_WEB_VITALS,
    STYLED_FOR_YOU_ITEM_CLICK,
    STYLED_FOR_YOU_LOAD_SUCCESS,
    STYLED_FOR_YOU_MODULE_CLICK,
    TRIGGER_HAIRCARE_MODAL,
    PDP_ALL_SKU_SELECTED,
    BNPL_ANALYTICS_EVENT,
    OOS_SPONSORED_PRODUCTFORM_ERROR,
    PLCC_ANALYTICS_EVENT,
};
