import AnalyticsActionTypes from '../actionTypes/AnalyticsActionTypes';

export const styledForYouItemClick = (payload) => ({
    type: AnalyticsActionTypes.STYLED_FOR_YOU_ITEM_CLICK,
    payload,
});

export const styledForYouLoadSuccess = (payload) => ({
    type: AnalyticsActionTypes.STYLED_FOR_YOU_LOAD_SUCCESS,
    payload,
});

export const styledForYouModuleClick = (payload) => ({
    type: AnalyticsActionTypes.STYLED_FOR_YOU_MODULE_CLICK,
    payload,
});

export const triggerFormError = (errorDetails, pageInfo = '') => ({
    type: AnalyticsActionTypes.FORM_ERROR,
    errorDetails,
    pageInfo,
});

export const triggerOOSSponsoredProductFormError = (errorDetails) => ({
    type: AnalyticsActionTypes.OOS_SPONSORED_PRODUCTFORM_ERROR,
    errorDetails,
});

export const triggerNavigationClick = (payload) => ({
    type: AnalyticsActionTypes.NAVIGATION_CLICK,
    navClick: payload,
});

export const triggerAppBannerOpen = () => ({
    type: AnalyticsActionTypes.APP_BANNER_OPEN,
});

export const triggerAppBannerClose = () => ({
    type: AnalyticsActionTypes.APP_BANNER_CLOSE,
});

export const storeSearchData = (payload) => ({
    type: AnalyticsActionTypes.ANALYTICS_STORE_SEARCH,
    payload,
});

// Gallery store change event
export const bopisChangeZip = (payload) => ({
    type: AnalyticsActionTypes.BOPIS_CHANGE_ZIP,
    payload,
});

export const videoLoadedData = (payload) => ({
    type: AnalyticsActionTypes.ANALYTICS_VIDEO_LOADED,
    payload,
});
/* istanbul ignore next */
export const openModelEvent = (payload) => ({
    type: AnalyticsActionTypes.ANALYTICS_OPEN_MODAL,
    name: payload.name,
});

export const updateAnalyticsClickAction = (payload) => ({
    type: AnalyticsActionTypes.UPDATE_ANALYTICS_CLICK_EVENT,
    payload,
});

export const removeAnalyticsClickAction = (payload) => ({
    type: AnalyticsActionTypes.REMOVE_ANALYTICS_CLICK_EVENT,
    payload,
});

export const compareProductsClick = (payload) => ({
    type: AnalyticsActionTypes.COMPARE_PRODUCTS_CLICK,
    payload,
});

export const pdpToolClickAnalytics = (payload) => ({
    type: AnalyticsActionTypes.PDP_TOOL_CLICK_ANALYTICS,
    payload,
});

export const pdpKaClickAnalytics = (payload) => ({
    type: AnalyticsActionTypes.PDP_KA_CLICK_ANALYTICS,
    payload,
});

export const productOOSAnalytics = () => ({
    type: AnalyticsActionTypes.PDP_PRODUCT_OOS_ANALYTICS,
});

export const openModalAnalytics = (payload) => ({
    type: AnalyticsActionTypes.PDP_OPEN_MODAL_ANALYTICS,
    payload,
});

export const productRecommendationAnalytics = (payload) => ({
    type: AnalyticsActionTypes.PRODUCT_RECOMMENDATIONS_ANALYTICS,
    payload,
});

export const saveBreadCrumbData = (breadcrumbData) => ({
    type: AnalyticsActionTypes.BREADCRUMB_ANALYTICS,
    breadcrumbData,
});

export const promotedFilterChange = (payload) => ({
    type: AnalyticsActionTypes.PROMOTED_FILTERS_APPLIED,
    payload,
});

export const triggerFilterByPillsAnalytics = (payload) => ({
    type: AnalyticsActionTypes.FILTERS_BY_PILLS_ANALYTICS,
    payload,
});

export const fitFinderModalAndPersonaAnalytics = () => ({
    type: AnalyticsActionTypes.FITFINDER_MODAL_AND_PERSONA,
});

export const triggerPageReady = () => ({
    type: AnalyticsActionTypes.TRIGGER_PAGE_READY,
});

export const triggerActiveCartReminder = () => ({
    type: AnalyticsActionTypes.TRIGGER_ACTIVE_CART_REMINDER,
});

export const triggerBopisModal = () => ({
    type: AnalyticsActionTypes.TRIGGER_BOPIS_MODAL,
});

export const triggerBopisPickupStoreChange = () => ({
    type: AnalyticsActionTypes.TRIGGER_BOPIS_PICKUP_STORE_CHANGE,
});

export const triggerBopisDefaultStoreChange = () => ({
    type: AnalyticsActionTypes.TRIGGER_BOPIS_DEFAULT_STORE_CHANGE,
});

export const triggerRevieveAddToCartAnalytics = (payload) => ({
    type: AnalyticsActionTypes.TRIGGER_REVIEVE_ADDTOCART_ANALYTICS,
    payload,
});

export const triggerSkinCareModal = () => ({
    type: AnalyticsActionTypes.TRIGGER_SKINCARE_MODAL,
});

export const triggerVTOModal = () => ({
    type: AnalyticsActionTypes.TRIGGER_VTO_MODAL,
});

export const plccApplyAnalytics = (payload) => ({
    type: AnalyticsActionTypes.PLCC_APPLY_ANALYTICS,
    payload,
});

export const plccViewsAnalytics = (payload) => ({
    type: AnalyticsActionTypes.PLCC_VIEWS_ANALYTICS,
    payload,
});

export const subscriptionSuccessAnalytics = (payload) => ({
    type: AnalyticsActionTypes.ANALYTICS_SUCCESSFUL_SUBSCRIPTION,
    payload,
});

export const shopTheLookLoadSuccess = (payload) => ({
    type: AnalyticsActionTypes.PDP_SHOP_THE_LOOK_READY,
    payload,
});

export const shopThisLookClickEvent = (payload) => ({
    type: AnalyticsActionTypes.PDP_SHOP_THIS_LOOK_CLICK_EVENT,
    payload,
});

export const shopTheLookMiniPDPClickEvent = (payload) => ({
    type: AnalyticsActionTypes.PDP_SHOP_THE_LOOK_MINI_PDP_CLICK_EVENT,
    payload,
});

export const reportWebVitals = () => ({
    type: AnalyticsActionTypes.REPORT_WEB_VITALS,
});

export const triggerHairCareModal = () => ({
    type: AnalyticsActionTypes.TRIGGER_HAIRCARE_MODAL,
});

export const allSKUSelected = (payload) => ({
    type: AnalyticsActionTypes.PDP_ALL_SKU_SELECTED,
    payload,
});

export const triggerBnplAnalytics = (eventType, statusCode) => {
    const payload = { eventType };
    if (statusCode) {
        payload.statusCode = statusCode;
    }
    return { type: AnalyticsActionTypes.BNPL_ANALYTICS_EVENT, payload };
};

export const triggerPlccAnalytics = (payload) => {
    return { type: AnalyticsActionTypes.PLCC_ANALYTICS_EVENT, payload };
};

export default {
    triggerFormError,
    triggerNavigationClick,
    triggerAppBannerOpen,
    triggerAppBannerClose,
    videoLoadedData,
    openModelEvent,
    updateAnalyticsClickAction,
    removeAnalyticsClickAction,
    storeSearchData,
    bopisChangeZip,
    compareProductsClick,
    pdpToolClickAnalytics,
    pdpKaClickAnalytics,
    openModalAnalytics,
    productOOSAnalytics,
    productRecommendationAnalytics,
    saveBreadCrumbData,
    promotedFilterChange,
    triggerFilterByPillsAnalytics,
    fitFinderModalAndPersonaAnalytics,
    triggerPageReady,
    triggerActiveCartReminder,
    triggerBopisModal,
    triggerBopisPickupStoreChange,
    triggerBopisDefaultStoreChange,
    triggerRevieveAddToCartAnalytics,
    triggerSkinCareModal,
    triggerVTOModal,
    plccApplyAnalytics,
    plccViewsAnalytics,
    subscriptionSuccessAnalytics,
    shopTheLookLoadSuccess,
    shopThisLookClickEvent,
    shopTheLookMiniPDPClickEvent,
    reportWebVitals,
    triggerHairCareModal,
    allSKUSelected,
    triggerBnplAnalytics,
    triggerOOSSponsoredProductFormError,
    styledForYouItemClick,
    styledForYouLoadSuccess,
    styledForYouModuleClick,
    triggerPlccAnalytics,
};
